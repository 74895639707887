import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import videoSrc from '../assets/fabrication-720.mp4'
import { StaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'

class FabricationPage extends React.Component {
    render() { 
        return (
        <Layout containerClass="fabrication">
            <SEO title="Fabrication & Metalworking" />
            <Hero title="Fabrication" video={videoSrc} mobile={this.props.data.header.childImageSharp.fluid}/>
            <section class="main" id="main">
                <div className="uk-flex uk-flex-middle uk-flex-wrap">
                    <div className="uk-width-1-1 uk-width-1-2@l text">
                        <h2 class="h1">Stainless and aluminium - Fabrication, Repairs & Maintenance</h2>
                        <p>
                            With an experienced and knowledgeable background in Stainless steel and Aluminium we can fabricate or repair
                            your project from start to finish. We take great pride in our ability to complete clients projects to specification 
                            and to budget.
                        </p>
                        <p>
                            We are by no means limited to this one material and we welcome the opportunity to fabricate in many materials. 
                            from one off designs to complete design and manufacturing briefs to meet our clients requirements
                        </p>
                    </div>
                    <div className="uk-width-1-1 uk-width-1-2@l uk-cover-container uk-height-viewport">
                        <Img fluid={this.props.data.steel.childImageSharp.fluid}  className="uk-height-viewport" />
                    </div>
                </div>
                <div className="uk-flex uk-flex-middle uk-flex-wrap uk-flex-row-reverse">
                    <div className="uk-width-1-1 uk-width-1-2@l text">
                        <h2 class="h1">Stainless Bench tops</h2>
                        <p>
                            Nothing gives a kitchen the ability to last longer than using superior metals, to stand the test of time. 
                            Stainless steel is becoming the preferred choice in domestic indoor and outdoor kitchens.</p>
                        <p>
                            Unlike other surfaces, stainless steel will not chip, crack or fade over time. 
                            Both its durability and modern style have proven to outlive any other materials. 
                        </p>
                        <p>
                            Stainless steel provides a hygienic, easy to clean modern kitchen as well as simply fitting in with new contemporary design
                        </p>
                    </div>
                    <div className="uk-width-1-1 uk-width-1-2@l uk-cover-container uk-height-viewport">
                        <Img fluid={this.props.data.bench.childImageSharp.fluid} className="uk-height-viewport" />
                    </div>
                    
                </div>
                <div className="testimonials">
                    <div className="uk-container uk-container-center">
                        <div className="uk-flex uk-flex-middle uk-flex-center uk-flex-wrap">
                            <div className="uk-width-1-1 uk-width-2-3@l">
                                <p className="h2">Mark & Tess Williamson - Warrington</p>
                                <p>
                                    <i>
                                        We just want to let you know how delighted we are by the bench tops.  They look so good, fit so well, and I am sure they will serve us well for the years to come.
                                        Many thanks to all those involved, and especially to Luke for his excellent templating.
                                        Again, our sincere thanks for an excellent job.
                                    </i>
                                </p>

                                <p className="h2">Gen Orbell - Mornington</p>        
                                <p><i>
                                    I just wanted to say I love my benches! Thank you soo much. You did such a great job
                                    </i>    
                                </p>
                                

                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-flex uk-flex-middle uk-flex-wrap">
                    <div className="uk-width-1-1 uk-width-1-2@l text">
                        <h2 class="h1">Structual Steel</h2>
                        <p>
                        We have the ability and passion to complete structural steel work and earthquake strengthening. 
                        From manufacture and supply through to onsite installations with our team of certified people and experienced
                        installers, we have never missed a deadline. 
                        </p>
                    </div>
                    <div className="uk-width-1-1 uk-width-1-2@l uk-cover-container uk-height-viewport ">
                        <Img fluid={this.props.data.pipe.childImageSharp.fluid}  className="uk-height-viewport structual-fix" />
                    </div>
                </div>
            </section>
        </Layout>
        )
    }
}

export default FabricationPage


export const query = graphql`
  query fabricationQuery {
    header:file(relativePath: { eq: "home/fab.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1200) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
    steel:file(relativePath: { eq: "new/PH03.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1200) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
    bench:file(relativePath: { eq: "new/PH04.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1200) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
    pipe:file(relativePath: { eq: "new/PH06.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 1200) {
                src
                srcSet
                aspectRatio
                sizes
                base64
            }
          }
    }
  }
`;
